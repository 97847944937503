import { GREY_6, LIGHT_GREY, WHITE } from 'design-system/global/colors';

interface Props {
  selected: boolean;
  darkMode: boolean;
}

export const SlabSerif = ({ selected, darkMode }: Props) => {
  const getColor = (): string => {
    if (!selected) {
      return LIGHT_GREY;
    } else {
      if (!darkMode) {
        return GREY_6;
      } else {
        return WHITE;
      }
    }
  };

  return (
    <svg
      width="29"
      height="36"
      viewBox="0 0 29 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.977539 36V32.9238L4.95703 32.2402V4.2373L0.977539 3.55371V0.453125H27.2959V8.99805H23.4385L22.9014 4.16406H9.7666V15.7363H22.877V19.5205H9.7666V32.3867H23.6826L24.2197 27.4795H28.0527V36H0.977539Z"
        fill={getColor()}
      />
    </svg>
  );
};

SlabSerif.defaultProps = {
  selected: false,
  darkMode: false,
};
