import { GREY_6, LIGHT_GREY, WHITE } from 'design-system/global/colors';

interface Props {
  selected: boolean;
  darkMode: boolean;
}

export const Other = ({ selected, darkMode }: Props) => {
  const getColor = (): string => {
    if (!selected) {
      return LIGHT_GREY;
    } else {
      if (!darkMode) {
        return GREY_6;
      } else {
        return WHITE;
      }
    }
  };

  return (
    <svg
      className="other"
      width="41"
      height="13"
      viewBox="0 0 41 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20.4501"
        cy="6.50017"
        r="5.56876"
        fill={getColor()}
      />
      <circle
        cx="5.60001"
        cy="6.50017"
        r="5.56876"
        fill={getColor()}
      />
      <circle
        cx="35.3002"
        cy="6.50017"
        r="5.56876"
        fill={getColor()}
      />
    </svg>
  );
};

Other.defaultProps = {
  selected: false,
  darkMode: false,
};
