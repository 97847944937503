import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface Props {
  darkMode: boolean;
  visible?: boolean;
  left?: string;
  top?: string;
}

export const TooltipWrapper = styled.div`
  position: absolute;
  padding: 9px 13px;
  border-radius: 8px;
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  box-shadow: ${(props: Props) => (props.darkMode ? 'none' : `2px 2px 0px ${GREY_6}`)};
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  min-width: 30px;
  pointer-events: none;
  visibility: ${(props: Props) => (props.visible ? 'visible' : 'hidden')};
  ${(props: Props) =>
    props.left &&
    css`
      left: ${props.left};
    `}
  ${(props: Props) =>
    props.top &&
    css`
      top: ${props.top};
    `}

  svg {
    position: absolute;
    left: 50%;
    bottom: -7px;
    transform: translate(calc(-50% + 2px), 0);
  }
`;
