import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { trackFontFilterChanged } from 'tracking/Mixpanel';
import { useSettingsContext } from 'context';
import Tooltip from 'components/Tooltip';
import { HexagonalSans, Other, SansSerif, Serif, SlabSerif, TriangleSerif } from 'components/FontStyles';
import { initialFontStyles } from 'common/data/Settings';
import {
  ContentWrapper,
  FontShapesWrapper,
  ResetButton,
  ShapeItemSvg,
  ShapeItemWrapper,
  ShapesWrapper,
  Title,
  TitleWrapper,
} from './FontShapes.styled';

interface Props {
  onFiltersChanged?: () => void;
}

const FontShapes = ({ onFiltersChanged }: Props) => {
  const { t } = useTranslation();
  const { darkMode, fontStyles, changeFontStyles, resetFontShapes } = useSettingsContext();

  const [shapeClicked, setShapeClicked] = useState<boolean>(false);
  const [showResetButton, setShowResetButton] = useState<boolean>(false);

  const handleMouseOutFontShape = useCallback(() => {
    setShapeClicked(false);
  }, []);

  const handleClickFontShape = useCallback(
    (event: SyntheticEvent<HTMLDivElement>) => {
      const key = event.currentTarget.dataset.value;
      if (key) {
        const newValue: boolean = !fontStyles[key];
        changeFontStyles(key, newValue);
        setShapeClicked(true);
        if (newValue === true) {
          // Track Mixpanel Font filter changed: category
          trackFontFilterChanged('category', t(`font-shape.font-styles.${key}`));
        }
        onFiltersChanged?.();
      }
    },
    [changeFontStyles, fontStyles, onFiltersChanged, t]
  );

  const handleResetFontShapes = useCallback(() => {
    resetFontShapes();
    onFiltersChanged?.();
  }, [onFiltersChanged, resetFontShapes]);

  useEffect(() => {
    if (JSON.stringify(fontStyles) !== JSON.stringify(initialFontStyles)) {
      setShowResetButton(true);
      return;
    }
    setShowResetButton(false);
  }, [fontStyles]);

  return (
    <FontShapesWrapper darkMode={darkMode}>
      <ContentWrapper>
        <TitleWrapper>
          <Title>{t('font-shape.title')}</Title>
          {showResetButton && (
            <ResetButton
              darkMode={darkMode}
              onClick={handleResetFontShapes}
            >
              {t('font-shape.reset')}
            </ResetButton>
          )}
        </TitleWrapper>
        <ShapesWrapper>
          {/* SERIF */}
          <ShapeItemWrapper
            darkMode={darkMode}
            selected={fontStyles['serif']}
            shapeClicked={shapeClicked}
            data-value="serif"
            onMouseOut={handleMouseOutFontShape}
            onClick={handleClickFontShape}
          >
            <ShapeItemSvg>
              <Serif
                selected={fontStyles['serif']}
                darkMode={darkMode}
              />
            </ShapeItemSvg>
            <Tooltip darkMode={darkMode}>{t('font-shape.font-styles.serif')}</Tooltip>
          </ShapeItemWrapper>
          {/* TRIANGLE SERIF */}
          <ShapeItemWrapper
            darkMode={darkMode}
            selected={fontStyles['triangle-serif']}
            shapeClicked={shapeClicked}
            data-value="triangle-serif"
            onMouseOut={handleMouseOutFontShape}
            onClick={handleClickFontShape}
          >
            <ShapeItemSvg>
              <TriangleSerif
                selected={fontStyles['triangle-serif']}
                darkMode={darkMode}
              />
            </ShapeItemSvg>
            <Tooltip darkMode={darkMode}>{t('font-shape.font-styles.triangle-serif')}</Tooltip>
          </ShapeItemWrapper>
          {/* SLAB SERIF */}
          <ShapeItemWrapper
            darkMode={darkMode}
            selected={fontStyles['slab-serif']}
            shapeClicked={shapeClicked}
            data-value="slab-serif"
            onMouseOut={handleMouseOutFontShape}
            onClick={handleClickFontShape}
          >
            <ShapeItemSvg>
              <SlabSerif
                selected={fontStyles['slab-serif']}
                darkMode={darkMode}
              />
            </ShapeItemSvg>
            <Tooltip darkMode={darkMode}>{t('font-shape.font-styles.slab-serif')}</Tooltip>
          </ShapeItemWrapper>
          {/* SANS SERIF */}
          <ShapeItemWrapper
            darkMode={darkMode}
            selected={fontStyles['sans-serif']}
            shapeClicked={shapeClicked}
            data-value="sans-serif"
            onMouseOut={handleMouseOutFontShape}
            onClick={handleClickFontShape}
          >
            <ShapeItemSvg>
              <SansSerif
                selected={fontStyles['sans-serif']}
                darkMode={darkMode}
              />
            </ShapeItemSvg>
            <Tooltip darkMode={darkMode}>{t('font-shape.font-styles.sans-serif')}</Tooltip>
          </ShapeItemWrapper>
          {/* HEXAGONAL SANS */}
          <ShapeItemWrapper
            darkMode={darkMode}
            selected={fontStyles['hexagonal-sans']}
            shapeClicked={shapeClicked}
            data-value="hexagonal-sans"
            onMouseOut={handleMouseOutFontShape}
            onClick={handleClickFontShape}
          >
            <ShapeItemSvg>
              <HexagonalSans
                selected={fontStyles['hexagonal-sans']}
                darkMode={darkMode}
              />
            </ShapeItemSvg>
            <Tooltip darkMode={darkMode}>{t('font-shape.font-styles.hexagonal-sans')}</Tooltip>
          </ShapeItemWrapper>
          {/* OTHER */}
          <ShapeItemWrapper
            darkMode={darkMode}
            selected={fontStyles['other']}
            shapeClicked={shapeClicked}
            data-value="other"
            onMouseOut={handleMouseOutFontShape}
            onClick={handleClickFontShape}
          >
            <ShapeItemSvg>
              <Other
                selected={fontStyles['other']}
                darkMode={darkMode}
              />
            </ShapeItemSvg>
            <Tooltip darkMode={darkMode}>{t('font-shape.font-styles.other')}</Tooltip>
          </ShapeItemWrapper>
        </ShapesWrapper>
      </ContentWrapper>
    </FontShapesWrapper>
  );
};

export default FontShapes;
