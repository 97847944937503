import { GREY_6, LIGHT_GREY, WHITE } from 'design-system/global/colors';

interface Props {
  selected: boolean;
  darkMode: boolean;
}

export const SansSerif = ({ selected, darkMode }: Props) => {
  const getColor = (): string => {
    if (!selected) {
      return LIGHT_GREY;
    } else {
      if (!darkMode) {
        return GREY_6;
      } else {
        return WHITE;
      }
    }
  };

  return (
    <svg
      width="24"
      height="35"
      viewBox="0 0 24 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.399902 35H23.3499V31H4.7499V19.2H19.6999V15.2H4.7499V4H22.6999V0H0.399902V35Z"
        fill={getColor()}
      />
    </svg>
  );
};

SansSerif.defaultProps = {
  selected: false,
  darkMode: false,
};
