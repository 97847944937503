import { GREY_6, LIGHT_GREY, WHITE } from 'design-system/global/colors';

interface Props {
  selected: boolean;
  darkMode: boolean;
}

export const HexagonalSans = ({ selected, darkMode }: Props) => {
  const getColor = (): string => {
    if (!selected) {
      return LIGHT_GREY;
    } else {
      if (!darkMode) {
        return GREY_6;
      } else {
        return WHITE;
      }
    }
  };

  return (
    <svg
      width="23"
      height="35"
      viewBox="0 0 23 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.45 35L0.5 28.5V6.45L5.45 0H22.4V3.35H7.15L3.9 7.6V15.9H18.75V19.25H3.9V27.35L7.15 31.65H22.4V35H5.45Z"
        fill={getColor()}
      />
    </svg>
  );
};

HexagonalSans.defaultProps = {
  selected: false,
  darkMode: false,
};
