import styled from '@emotion/styled';
import { mq } from 'styles/mediaqueries.styled';

export const FiltersWrapper = styled.div`
  display: flex;
  /* MOBILE */
  flex-direction: column;
  width: 345px;
  margin: -8px;
  /* DESKTOP */
  ${mq['desktop']} {
    flex-direction: row;
    width: auto;
    height: 100%;
    margin: 0;
  }
`;
