import { useCallback } from 'react';
import FontShapes from './FontShapes';
import FontStyles from './FontStyles';
import FontOptions from './FontOptions';
import { disableGlobalScroll, enableGlobalScroll } from 'common/utils';
import { FiltersWrapper } from './OptionsBarFilters.styled';

interface Props {
  hideFontShapes: boolean;
  hideFontWeightSlider: boolean;
  insideModal?: boolean;
}

const OptionsBarFilters = ({ hideFontShapes, hideFontWeightSlider, insideModal = false }: Props) => {
  const handleFiltersChanged = useCallback(() => {
    // If filters change and OptionsBarFilters are contained in a Modal
    // enable and then disable scroll for Safari Mobile to reinit scroll at first cards
    if (insideModal) {
      enableGlobalScroll();
      setTimeout(() => {
        disableGlobalScroll();
      }, 0);
    }
  }, [insideModal]);

  return (
    <FiltersWrapper>
      {!hideFontShapes && <FontShapes onFiltersChanged={handleFiltersChanged} />}
      <FontStyles onFiltersChanged={handleFiltersChanged} />
      <FontOptions
        hideFontWeightSlider={hideFontWeightSlider}
        onFiltersChanged={handleFiltersChanged}
      />
    </FiltersWrapper>
  );
};

export default OptionsBarFilters;
