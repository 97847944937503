import { PropsWithChildren } from 'react';
import Arrow from './Arrow';
import { TooltipWrapper } from './Tooltip.styled';

interface Props extends PropsWithChildren<Record<string, unknown>> {
  darkMode: boolean;
  visible?: boolean;
  left?: string;
  top?: string;
}

const Tooltip = ({ darkMode, visible, left, top, children }: Props) => {
  return (
    <TooltipWrapper
      className="tooltip"
      darkMode={darkMode}
      visible={visible}
      left={left}
      top={top}
    >
      <Arrow darkMode={darkMode} />
      {children}
    </TooltipWrapper>
  );
};

export default Tooltip;
