import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface Props {
  darkMode: boolean;
}

const Arrow = ({ darkMode }: Props) => {
  return (
    <svg
      width="42"
      height="14"
      viewBox="0 0 42 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00001 7L2 10H17.4985L20.5877 12.5453C21.3148 13.1444 22.3614 13.1549 23.1002 12.5704L26.3498 10H41.7128L41.7129 7H2.00001Z"
        fill={darkMode ? 'transparent' : GREY_6}
      />
      <path
        d="M8.00001 1L1.43572e-06 7L0 8H15.4985L18.5877 10.5453C19.3148 11.1444 20.3614 11.1549 21.1002 10.5704L24.3498 8H39.7128L31.7129 1H8.00001Z"
        fill={darkMode ? GREY_2 : GREY_6}
      />
      <path
        d="M1.005e-05 0L0 7H15.4985L18.5877 9.54534C19.3148 10.1444 20.3614 10.1549 21.1002 9.57041L24.3498 7H39.7128L39.7129 0H1.005e-05Z"
        fill={darkMode ? GREY_6 : WHITE}
      />
    </svg>
  );
};

export default Arrow;
