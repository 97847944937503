import styled from '@emotion/styled';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';
import { easeInOutQuart } from 'common/easing/Easing';
import { BOTTOM } from './OptionsBar.styled';

interface Props {
  show?: boolean;
  darkMode?: boolean;
}

const TOOLTIP_HEIGHT = '210px';

const tooltipHidden = `calc(${BOTTOM} + ${TOOLTIP_HEIGHT})`;

export const TooltipContainer = styled.div`
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: ${BOTTOM};
  opacity: ${(props: Props) => (props.show ? 1 : 0)};
  transform: translate(-50%, ${(props: Props) => (props.show ? 0 : tooltipHidden)});
  transition: ${(props: Props) =>
    props.show
      ? `transform 0.4s ${easeInOutQuart}, opacity 0.4s ${easeInOutQuart}`
      : `transform 0.4s ${easeInOutQuart}, opacity 0.4s ${easeInOutQuart} 0.05s`};
`;

export const TooltipWrapper = styled.div`
  position: relative;
  height: ${TOOLTIP_HEIGHT};
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  box-shadow: ${(props: Props) => (props.darkMode ? 'none' : `4px 4px 0px ${GREY_6}`)};
  border-radius: 8px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`;
