import { GREY_6, LIGHT_GREY, WHITE } from 'design-system/global/colors';

interface Props {
  selected: boolean;
  darkMode: boolean;
}

export const TriangleSerif = ({ selected, darkMode }: Props) => {
  const getColor = (): string => {
    if (!selected) {
      return LIGHT_GREY;
    } else {
      if (!darkMode) {
        return GREY_6;
      } else {
        return WHITE;
      }
    }
  };

  return (
    <svg
      width="28"
      height="35"
      viewBox="0 0 28 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.379883 35V34.168L4.53988 32.088V3.592L0.379883 1.512V0.68H25.8079V9.624H24.9759L21.7519 3.54H9.21988V15.864H18.0599L20.6599 11.548H21.4919V22.936H20.6599L18.0599 18.724H9.21988V32.14H23.2079L26.4319 25.536H27.2639V35H0.379883Z"
        fill={getColor()}
      />
    </svg>
  );
};

TriangleSerif.defaultProps = {
  selected: false,
  darkMode: false,
};
