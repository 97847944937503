import { PropsWithChildren, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { IconButton } from 'design-system/atoms/icon-button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useSettingsContext } from 'context';
import { CloseButton, TooltipContainer, TooltipWrapper } from './Tooltip.styled';

interface Props extends PropsWithChildren<Record<string, unknown>> {
  darkMode: boolean;
  optionsBarVisible?: boolean;
  optionsBarButtonRef: RefObject<HTMLButtonElement>;
}

const Tooltip = ({ children, darkMode, optionsBarVisible, optionsBarButtonRef }: Props) => {
  const { displayOptionsBarFilters, setDisplayOptionsBarFilters } = useSettingsContext();

  const [show, setShow] = useState<boolean>(false);

  const tooltipContainerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    setShow(false);
    tooltipContainerRef.current?.addEventListener('transitionend', () => {
      setDisplayOptionsBarFilters(false);
    });
  }, [setDisplayOptionsBarFilters]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // Avoid close tooltip when user click on Modal
      const modalEl = document.getElementById('modal');
      if (displayOptionsBarFilters && modalEl && modalEl.contains(target)) {
        return;
      }
      // User click outside tooltip => close tooltip
      if (
        displayOptionsBarFilters &&
        tooltipRef.current &&
        !tooltipRef.current.contains(target) &&
        optionsBarButtonRef.current &&
        !optionsBarButtonRef.current.contains(target)
      ) {
        handleClose();
      }
    },
    [displayOptionsBarFilters, handleClose, optionsBarButtonRef]
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (displayOptionsBarFilters) {
      setShow(true);
    }
  }, [displayOptionsBarFilters]);

  // Hide tooltip opened if options bar is hidden
  useEffect(() => {
    if (!optionsBarVisible && show) {
      handleClose();
    }
  }, [handleClose, optionsBarVisible, show]);

  useEffect(() => {
    if (displayOptionsBarFilters) {
      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('keyup', handleKeyUp);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [displayOptionsBarFilters, handleClickOutside, handleKeyUp]);

  return (
    <TooltipContainer
      ref={tooltipContainerRef}
      show={show}
    >
      <TooltipWrapper
        ref={tooltipRef}
        darkMode={darkMode}
      >
        {children}
        <CloseButton>
          <IconButton
            iconLabel="close"
            iconSize="8px"
            buttonSize="30px"
            color={darkMode ? WHITE : GREY_6}
            onClick={handleClose}
          />
        </CloseButton>
      </TooltipWrapper>
    </TooltipContainer>
  );
};

export default Tooltip;
