import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Icon } from 'design-system/atoms/icon';
import { IconButton } from 'design-system/atoms/icon-button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useBreakpointContext, useSettingsContext } from 'context';
import Modal from 'components/Modal';
import Tooltip from './Tooltip';
import OptionsBarFilters from 'containers/OptionsBarFilters';
import {
  BarButtonIconWrapper,
  BarButtonLabel,
  CloseButtonModal,
  OptionsBarButton,
  OptionsBarButtonContent,
  OptionsBarContainer,
  OptionsBarTooltipLayout,
} from './OptionsBar.styled';

interface Props {
  show?: boolean;
  hideFontShapes?: boolean;
  hideFontWeightSlider?: boolean;
}

const OptionsBar = ({ show, hideFontShapes = false, hideFontWeightSlider = false }: Props) => {
  const { t } = useTranslation('common');
  const { route } = useRouter();
  const { isMobile, isTablet } = useBreakpointContext();
  const { darkMode, displayOptionsBarFilters, setDisplayOptionsBarFilters, resetFontShapes } = useSettingsContext();

  const [startCloseModal, setStartCloseModal] = useState<boolean>(false);

  const optionsBarButtonRef = useRef<HTMLButtonElement>(null);

  const handleClickOptionsBar = useCallback(() => {
    setDisplayOptionsBarFilters(!displayOptionsBarFilters);
  }, [displayOptionsBarFilters, setDisplayOptionsBarFilters]);

  const handleCloseModal = useCallback(() => {
    setStartCloseModal(true);
  }, []);

  const handleCloseModalComplete = useCallback(() => {
    setStartCloseModal(false);
    setDisplayOptionsBarFilters(false);
  }, [setDisplayOptionsBarFilters]);

  useEffect(() => {
    return () => {
      // Force to close tooltip/modal when route change
      route;
      setDisplayOptionsBarFilters(false);
      // Reinit font shapes when route change
      resetFontShapes();
    };
  }, [resetFontShapes, route, setDisplayOptionsBarFilters]);

  return (
    <>
      {/* DESKTOP TOOLTIP LAYOUT */}
      {!isMobile && !isTablet && displayOptionsBarFilters && <OptionsBarTooltipLayout />}

      {/* OPTIONS BAR BUTTON */}
      <OptionsBarContainer show={show}>
        <OptionsBarButton
          ref={optionsBarButtonRef}
          darkMode={darkMode}
          onMouseDown={handleClickOptionsBar}
        >
          <OptionsBarButtonContent active={displayOptionsBarFilters}>
            <BarButtonIconWrapper>
              <Icon
                label="font_options"
                color={darkMode ? GREY_6 : WHITE}
                fontSize="16px"
              />
            </BarButtonIconWrapper>
            <BarButtonLabel darkMode={darkMode}>{t('options-bar.filter-typefaces')}</BarButtonLabel>
          </OptionsBarButtonContent>
        </OptionsBarButton>
      </OptionsBarContainer>

      {/* MOBILE MODAL FILTERS */}
      {(isMobile || isTablet) && displayOptionsBarFilters && (
        <Modal
          darkMode={darkMode}
          startClose={startCloseModal}
          modalContainerOverflowHidden={false}
          onClose={handleCloseModal}
          onCloseComplete={handleCloseModalComplete}
        >
          <OptionsBarFilters
            hideFontShapes={hideFontShapes}
            hideFontWeightSlider={hideFontWeightSlider}
            insideModal={true}
          />
          <CloseButtonModal>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleCloseModal}
            />
          </CloseButtonModal>
        </Modal>
      )}

      {/* DESKTOP TOOLTIP FILTERS */}
      {!isMobile && !isTablet && displayOptionsBarFilters && (
        <Tooltip
          darkMode={darkMode}
          optionsBarVisible={show}
          optionsBarButtonRef={optionsBarButtonRef}
        >
          <OptionsBarFilters
            hideFontShapes={hideFontShapes}
            hideFontWeightSlider={hideFontWeightSlider}
          />
        </Tooltip>
      )}
    </>
  );
};

export default OptionsBar;
