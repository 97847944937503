import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Slider } from 'design-system/atoms/slider';
import { Checkbox } from 'design-system/atoms/checkbox';
import { trackFontFilterChanged, trackListStyleChanged } from 'tracking/Mixpanel';
import { useAuthContext, useModalContext, useSettingsContext } from 'context';
import {
  initialCurrency,
  initialDisplayedWeight,
  initialMonospacedFonts,
  initialPriceRange,
  initialTrialFonts,
} from 'common/data/Settings';
import { priceRangeOptions, weightIcons } from 'common/data/Datas';
import {
  ContentWrapper,
  FilterItemLabel,
  FilterItemWrapper,
  FilterItemsContainer,
  FontOptionsWrapper,
  ResetButton,
  Title,
  TitleWrapper,
} from './FontOptions.styled';

interface Props {
  hideFontWeightSlider: boolean;
  onFiltersChanged?: () => void;
}

let resetFilters: boolean = false;

const FontOptions = ({ hideFontWeightSlider, onFiltersChanged }: Props) => {
  const { t } = useTranslation();
  const {
    darkMode,
    priceRange,
    changePriceRange,
    displayedWeight,
    changeDisplayedWeight,
    trialFonts,
    changeTrialFonts,
    monospacedFonts,
    changeMonospacedFonts,
    resetFontOptions,
  } = useSettingsContext();
  const { preferredCurrency } = useAuthContext();
  const { setOpenModalPriceRangeWarning } = useModalContext();

  const [showResetButton, setShowResetButton] = useState<boolean>(false);

  const selectedCurrency = useMemo(() => preferredCurrency ?? initialCurrency, [preferredCurrency]);

  const [priceRangeValue, setPriceRangeValue] = useState<Array<number>>(priceRange);
  const [priceRangeLabel, setPriceRangeLabel] = useState<Array<string>>([
    (priceRangeOptions[selectedCurrency] as any).find((o: any) => o.value === priceRange[0]).label,
    (priceRangeOptions[selectedCurrency] as any).find((o: any) => o.value === priceRange[1]).label,
  ]);

  const [iconWeightSlider, setIconWeightSlider] = useState<string>(
    weightIcons.find((o) => o.value === displayedWeight)!.icon
  );
  const [labelWeightSlider, setLabelWeightSlider] = useState<string>(
    weightIcons.find((o) => o.value === displayedWeight)!.label
  );
  const marksWeightSlider = useMemo(() => weightIcons.map((w) => w.value), []);

  const handleChangePrice = useCallback(
    (value: number | readonly number[], index: number) => {
      if (Array.isArray(value)) {
        if (index < 0) index = 0;
        const currentValue = value[index];
        priceRangeValue[index] = currentValue;
        setPriceRangeValue(priceRangeValue);
        const currentLabel = (priceRangeOptions[selectedCurrency] as any).find(
          (o: any) => o.value === currentValue
        ).label;
        priceRangeLabel[index] = currentLabel;
        setPriceRangeLabel(priceRangeLabel);
      }
    },
    [priceRangeLabel, priceRangeValue, selectedCurrency]
  );

  const handleAfterChangePrice = useCallback(
    (value: number | readonly number[], index: number) => {
      if (Array.isArray(value)) {
        if (index < 0) index = 0;
        const currentValue = value[index];
        priceRangeValue[index] = currentValue;
        changePriceRange(priceRangeValue);

        priceRangeValue[0] === 0 && priceRangeValue[1] === 0
          ? setOpenModalPriceRangeWarning(true)
          : setOpenModalPriceRangeWarning(false);

        // Track Mixpanel Font filter changed: price
        const clonePriceRangeValue = JSON.parse(JSON.stringify(priceRangeValue));
        clonePriceRangeValue[0] === 125
          ? (clonePriceRangeValue[0] = '100+')
          : (clonePriceRangeValue[0] = clonePriceRangeValue[0].toString());
        clonePriceRangeValue[1] === 125
          ? (clonePriceRangeValue[1] = '100+')
          : (clonePriceRangeValue[1] = clonePriceRangeValue[1].toString());
        trackFontFilterChanged('price', clonePriceRangeValue);

        resetFilters = true;
        onFiltersChanged?.();
      }
    },
    [changePriceRange, onFiltersChanged, priceRangeValue, setOpenModalPriceRangeWarning]
  );

  const handleChangeWeight = useCallback((value: number | readonly number[], index: number) => {
    const currentValue = Array.isArray(value) ? value[index] : value;
    const currentIcon = weightIcons.find((o) => o.value === currentValue)!.icon;
    const currentLabel = weightIcons.find((o) => o.value === currentValue)!.label;
    setIconWeightSlider(currentIcon);
    setLabelWeightSlider(currentLabel);
  }, []);

  const handleAfterChangeWeight = useCallback(
    (value: number | readonly number[], index: number) => {
      const currentValue = Array.isArray(value) ? value[index] : value;
      changeDisplayedWeight(currentValue);
      // Track Mixpanel list style changed: displayed-weight
      trackListStyleChanged('displayed-weight', weightIcons.find((weight) => weight.value === currentValue)?.label);
    },
    [changeDisplayedWeight]
  );

  const handleChangeTrialFonts = useCallback(() => {
    const newValue = !trialFonts;
    changeTrialFonts(newValue);

    // Track Mixpanel Font filter changed: trial
    if (newValue === true) {
      trackFontFilterChanged('trial', newValue);
    }

    resetFilters = true;
    onFiltersChanged?.();
  }, [changeTrialFonts, onFiltersChanged, trialFonts]);

  const handleChangeMonospacedFonts = useCallback(() => {
    const newValue = !monospacedFonts;
    changeMonospacedFonts(newValue);

    // Track Mixpanel Font filter changed: trial
    if (newValue === true) {
      trackFontFilterChanged('monospace', newValue);
    }

    resetFilters = true;
    onFiltersChanged?.();
  }, [changeMonospacedFonts, monospacedFonts, onFiltersChanged]);

  const handleResetFontOptions = useCallback(() => {
    const resetDisplayedWeight = !hideFontWeightSlider;
    resetFontOptions(resetDisplayedWeight);

    if (resetFilters) {
      resetFilters = false;
      onFiltersChanged?.();
    }
  }, [hideFontWeightSlider, onFiltersChanged, resetFontOptions]);

  // Currency change
  useEffect(() => {
    setPriceRangeLabel([
      (priceRangeOptions[selectedCurrency] as any).find((o: any) => o.value === priceRange[0]).label,
      (priceRangeOptions[selectedCurrency] as any).find((o: any) => o.value === priceRange[1]).label,
    ]);
  }, [priceRange, selectedCurrency]);

  // Price range change
  useEffect(() => {
    setPriceRangeValue([priceRange[0], priceRange[1]]);
  }, [priceRange]);

  // Weight change
  useEffect(() => {
    setIconWeightSlider(weightIcons.find((o) => o.value === displayedWeight)!.icon);
    setLabelWeightSlider(weightIcons.find((o) => o.value === displayedWeight)!.label);
  }, [displayedWeight]);

  useEffect(() => {
    if (JSON.stringify(priceRange) !== JSON.stringify(initialPriceRange)) {
      setShowResetButton(true);
      return;
    }
    if (!hideFontWeightSlider && displayedWeight !== initialDisplayedWeight) {
      setShowResetButton(true);
      return;
    }
    if (trialFonts !== initialTrialFonts) {
      setShowResetButton(true);
      return;
    }
    if (monospacedFonts !== initialMonospacedFonts) {
      setShowResetButton(true);
      return;
    }
    setShowResetButton(false);
  }, [displayedWeight, hideFontWeightSlider, monospacedFonts, priceRange, trialFonts]);

  return (
    <FontOptionsWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <Title>{t('font-options.title')}</Title>
          {showResetButton && (
            <ResetButton
              darkMode={darkMode}
              onClick={handleResetFontOptions}
            >
              {t('font-options.reset')}
            </ResetButton>
          )}
        </TitleWrapper>
        <FilterItemsContainer>
          <FilterItemWrapper>
            <FilterItemLabel>{t('font-options.price-range')}</FilterItemLabel>
            <Slider
              value={priceRangeValue}
              defaultLabel={priceRangeLabel}
              marks={[0, 25, 50, 75, 100, 125]}
              step={25}
              min={0}
              max={125}
              darkMode={darkMode}
              onChange={handleChangePrice}
              onAfterChange={handleAfterChangePrice}
            />
          </FilterItemWrapper>
          {!hideFontWeightSlider && (
            <FilterItemWrapper>
              <FilterItemLabel>{t('font-options.weight')}</FilterItemLabel>
              <Slider
                value={displayedWeight}
                defaultIcon={iconWeightSlider}
                defaultLabel={labelWeightSlider}
                withTooltip
                min={0}
                max={1000}
                marks={marksWeightSlider}
                step={100}
                darkMode={darkMode}
                onChange={handleChangeWeight}
                onAfterChange={handleAfterChangeWeight}
              />
            </FilterItemWrapper>
          )}
          <FilterItemWrapper>
            <Checkbox
              checked={trialFonts}
              darkMode={darkMode}
              onChange={handleChangeTrialFonts}
            >
              {t('font-options.trial-fonts')}
            </Checkbox>
          </FilterItemWrapper>
          <FilterItemWrapper>
            <Checkbox
              checked={monospacedFonts}
              darkMode={darkMode}
              onChange={handleChangeMonospacedFonts}
            >
              {t('font-options.monospaced-fonts')}
            </Checkbox>
          </FilterItemWrapper>
        </FilterItemsContainer>
      </ContentWrapper>
    </FontOptionsWrapper>
  );
};

export default FontOptions;
