import styled from '@emotion/styled';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
}

export const FontStylesWrapper = styled.div`
  display: flex;
  /* MOBILE */
  padding: 40px 32px 40px 24px;
  border-bottom: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  /* DESKTOP */
  ${mq['desktop']} {
    width: 318px;
    padding: 32px 40px 40px;
    border-bottom: none;
    border-right: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  /* MOBILE */
  margin-bottom: 24px;
  /* DESKTOP */
  ${mq['desktop']} {
    margin-bottom: 16px;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  line-height: normal;
  /* MOBILE */
  font-size: 24px;
  letter-spacing: -0.24px;
  /* DESKTOP */
  ${mq['desktop']} {
    font-size: 16px;
    letter-spacing: normal;
  }
`;

export const ResetButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  padding: 0 8px;
  border-radius: 69px;
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  outline: none;
  cursor: pointer;
  /* MOBILE */
  margin-top: 4px;
  margin-left: 8px;
  /* DESKTOP */
  ${mq['desktop']} {
    margin-top: 1px;
    margin-left: 6px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;
  }
`;

export const FilterItemsContainer = styled.div`
  position: relative;
`;

export const FilterItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* MOBILE */
  margin-bottom: 32px;
  /* DESKTOP */
  ${mq['desktop']} {
    margin-bottom: 12px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const FilterItemLabel = styled.p`
  line-height: normal;
  /* MOBILE */
  font-size: 16px;
  min-width: 75px;
  margin-right: 3px;
  /* DESKTOP */
  ${mq['desktop']} {
    font-size: 14px;
    max-width: 66px;
    margin-right: 12px;
  }
`;
