import styled from '@emotion/styled';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { easeInOutBack, easeOutQuart } from 'common/easing/Easing';

interface Props {
  show?: boolean;
  darkMode?: boolean;
  active?: boolean;
}

export const BAR_HEIGHT = '53px';
export const BOTTOM = '32px';

const barHidden = `calc(${BOTTOM} + ${BAR_HEIGHT})`;

export const OptionsBarTooltipLayout = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const OptionsBarContainer = styled.div`
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: ${BOTTOM};
  transform: translate(-50%, ${(props: Props) => (props.show ? 0 : barHidden)});
  transition: transform 0.4s ${easeInOutBack};
`;

export const OptionsBarButton = styled.button`
  display: flex;
  width: auto;
  height: ${BAR_HEIGHT};
  border-radius: 102px;
  border: 0;
  outline: none;
  background-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  white-space: nowrap;
  cursor: pointer;
`;

export const OptionsBarButtonContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 26px;
  opacity: ${(props: Props) => (props.active ? 1 : 0.8)};
  transition: opacity 0.3s ${easeOutQuart};
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
`;

export const BarButtonIconWrapper = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

export const BarButtonLabel = styled.p`
  color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`;

export const CloseButtonModal = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`;
