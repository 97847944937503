import styled from '@emotion/styled';
import { GREY_2, GREY_6, LIGHT_GREY, WHITE } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
  selected?: boolean;
  shapeClicked?: boolean;
}

const border = ({ darkMode, selected }: Props): string => {
  if (!darkMode) {
    if (!selected) {
      return `1px dashed ${LIGHT_GREY}`;
    } else {
      return `1px solid ${GREY_6}`;
    }
  } else {
    if (!selected) {
      return `1px dashed ${LIGHT_GREY}`;
    } else {
      return `1px solid ${WHITE}`;
    }
  }
};

const backgroundColor = ({ darkMode }: Props): string => {
  if (darkMode) {
    return GREY_6;
  } else {
    return WHITE;
  }
};

export const FontShapesWrapper = styled.div`
  display: flex;
  /* MOBILE */
  padding: 32px 24px 40px;
  border-bottom: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  /* DESKTOP */
  ${mq['desktop']} {
    width: 241px;
    padding: 32px 40px 40px;
    border-bottom: none;
    border-right: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  /* MOBILE */
  margin-bottom: 24px;
  /* DESKTOP */
  ${mq['desktop']} {
    margin-bottom: 16px;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  line-height: normal;
  /* MOBILE */
  font-size: 24px;
  letter-spacing: -0.24px;
  /* DESKTOP */
  ${mq['desktop']} {
    font-size: 16px;
    letter-spacing: normal;
  }
`;

export const ResetButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  padding: 0 8px;
  border-radius: 69px;
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  outline: none;
  cursor: pointer;
  /* MOBILE */
  margin-top: 4px;
  margin-left: 8px;
  /* DESKTOP */
  ${mq['desktop']} {
    margin-top: 1px;
    margin-left: 6px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;
  }
`;

export const ShapesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ShapeItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 25%;
  border-radius: 8px;
  border: ${(props: Props) => border(props)};
  background-color: ${(props: Props) => backgroundColor(props)};
  cursor: pointer;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  &:nth-of-type(4),
  &:nth-of-type(5),
  &:nth-of-type(6) {
    margin-bottom: 0;
  }
  /* MOBILE */
  height: 88px;
  max-width: 88px;
  margin-right: 16px;
  margin-bottom: 16px;
  /* DESKTOP */
  ${mq['desktop']} {
    height: 48px;
    max-width: 48px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  @media (hover: hover) {
    &:hover {
      .tooltip {
        visibility: ${(props: Props) => (props.shapeClicked ? 'hidden' : 'visible')};
        z-index: 1;
        /* MOBILE */
        top: -28px;
        /* DESKTOP */
        ${mq['desktop']} {
          top: -36px;
        }
      }
    }
  }
`;

export const ShapeItemSvg = styled.div`
  pointer-events: none;
  svg {
    /* MOBILE */
    width: auto;
    height: 33px;
    /* DESKTOP */
    ${mq['desktop']} {
      height: 18px;
    }
  }

  .other {
    /* MOBILE */
    width: auto;
    height: 11px;
    /* DESKTOP */
    ${mq['desktop']} {
      height: 6px;
    }
  }
`;
