import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Slider } from 'design-system/atoms/slider';
import { trackFontFilterChanged } from 'tracking/Mixpanel';
import { useSettingsContext } from 'context';
import { contrastIcons, endingIcons, widthIcons } from 'common/data/Datas';
import { initialContrast, initialEnding, initialMinStyles, initialWidth } from 'common/data/Settings';
import {
  ContentWrapper,
  FilterItemLabel,
  FilterItemWrapper,
  FilterItemsContainer,
  FontStylesWrapper,
  ResetButton,
  Title,
  TitleWrapper,
} from './FontStyles.styled';

interface Props {
  onFiltersChanged?: () => void;
}

const FontStyles = ({ onFiltersChanged }: Props) => {
  const { t } = useTranslation('common');
  const {
    darkMode,
    minStyles,
    changeMinStyles,
    contrast,
    changeContrast,
    width,
    changeWidth,
    ending,
    changeEnding,
    resetFontStyles,
  } = useSettingsContext();

  const [showResetButton, setShowResetButton] = useState<boolean>(false);

  const [iconContrastSlider, setIconContrastSlider] = useState<string>(
    contrastIcons.find((o) => o.value === contrast)!.icon
  );
  const [labelContrastSlider, setLabelContrastSlider] = useState<string>(
    contrastIcons.find((o) => o.value === contrast)!.label
  );
  const marksContrastSlider = useMemo(() => contrastIcons.map((c) => c.value), []);

  const [iconWidthSlider, setIconWidthSlider] = useState<string>(widthIcons.find((o) => o.value === width)!.icon);
  const [labelWidthSlider, setLabelWidthSlider] = useState<string>(widthIcons.find((o) => o.value === width)!.label);
  const marksWidthSlider = useMemo(() => widthIcons.map((w) => w.value), []);

  const [iconEndingSlider, setIconEndingSlider] = useState<string>(endingIcons.find((o) => o.value === ending)!.icon);
  const [labelEndingSlider, setLabelEndingSlider] = useState<string>(
    endingIcons.find((o) => o.value === ending)!.label
  );
  const marksEndingSlider = useMemo(() => endingIcons.map((e) => e.value), []);

  const handleChangeMinStyles = useCallback(
    (value: number | readonly number[], index: number) => {
      const currentValue = Array.isArray(value) ? value[index] : value;
      changeMinStyles(currentValue);

      // Track Mixpanel Font filter changed: min-styles
      trackFontFilterChanged('min-styles', currentValue);

      onFiltersChanged?.();
    },
    [changeMinStyles, onFiltersChanged]
  );

  const handleChangeContrast = useCallback((value: number | readonly number[], index: number) => {
    const currentValue = Array.isArray(value) ? value[index] : value;
    const currentIcon = contrastIcons.find((o) => o.value === currentValue)!.icon;
    const currentLabel = contrastIcons.find((o) => o.value === currentValue)!.label;
    setIconContrastSlider(currentIcon);
    setLabelContrastSlider(currentLabel);
  }, []);

  const handleAfterChangeContrast = useCallback(
    (value: number | readonly number[], index: number) => {
      const currentValue = Array.isArray(value) ? value[index] : value;
      changeContrast(currentValue);

      // Track Mixpanel Font filter changed: contrast
      trackFontFilterChanged('contrast', contrastIcons.find((contrast) => contrast.value === currentValue)?.label);

      onFiltersChanged?.();
    },
    [changeContrast, onFiltersChanged]
  );

  const handleChangeWidth = useCallback((value: number | readonly number[], index: number) => {
    const currentValue = Array.isArray(value) ? value[index] : value;
    const currentIcon = widthIcons.find((o) => o.value === currentValue)!.icon;
    const currentLabel = widthIcons.find((o) => o.value === currentValue)!.label;
    setIconWidthSlider(currentIcon);
    setLabelWidthSlider(currentLabel);
  }, []);

  const handleAfterChangeWidth = useCallback(
    (value: number | readonly number[], index: number) => {
      const currentValue = Array.isArray(value) ? value[index] : value;
      changeWidth(currentValue);

      // Track Mixpanel Font filter changed: width
      trackFontFilterChanged('width', widthIcons.find((width) => width.value === currentValue)?.label);

      onFiltersChanged?.();
    },
    [changeWidth, onFiltersChanged]
  );

  const handleChangeEnding = useCallback((value: number | readonly number[], index: number) => {
    const currentValue = Array.isArray(value) ? value[index] : value;
    const currentIcon = endingIcons.find((o) => o.value === currentValue)!.icon;
    const currentLabel = endingIcons.find((o) => o.value === currentValue)!.label;
    setIconEndingSlider(currentIcon);
    setLabelEndingSlider(currentLabel);
  }, []);

  const handleAfterChangeEnding = useCallback(
    (value: number | readonly number[], index: number) => {
      const currentValue = Array.isArray(value) ? value[index] : value;
      changeEnding(currentValue);

      // Track Mixpanel Font filter changed: ending
      trackFontFilterChanged('ending', endingIcons.find((ending) => ending.value === currentValue)?.label);

      onFiltersChanged?.();
    },
    [changeEnding, onFiltersChanged]
  );

  const handleResetFontStyles = useCallback(() => {
    resetFontStyles();
    onFiltersChanged?.();
  }, [onFiltersChanged, resetFontStyles]);

  useEffect(() => {
    setIconContrastSlider(contrastIcons.find((o) => o.value === contrast)!.icon);
    setLabelContrastSlider(contrastIcons.find((o) => o.value === contrast)!.label);
    setIconWidthSlider(widthIcons.find((o) => o.value === width)!.icon);
    setLabelWidthSlider(widthIcons.find((o) => o.value === width)!.label);
    setIconEndingSlider(endingIcons.find((o) => o.value === ending)!.icon);
    setLabelEndingSlider(endingIcons.find((o) => o.value === ending)!.label);
  }, [contrast, ending, width]);

  useEffect(() => {
    if (minStyles !== initialMinStyles) {
      setShowResetButton(true);
      return;
    }
    if (contrast !== initialContrast) {
      setShowResetButton(true);
      return;
    }
    if (width !== initialWidth) {
      setShowResetButton(true);
      return;
    }
    if (ending !== initialEnding) {
      setShowResetButton(true);
      return;
    }
    setShowResetButton(false);
  }, [contrast, ending, minStyles, width]);

  return (
    <FontStylesWrapper darkMode={darkMode}>
      <ContentWrapper>
        <TitleWrapper>
          <Title>{t('font-styles.title')}</Title>
          {showResetButton && (
            <ResetButton
              darkMode={darkMode}
              onClick={handleResetFontStyles}
            >
              {t('font-styles.reset')}
            </ResetButton>
          )}
        </TitleWrapper>
        <FilterItemsContainer>
          <FilterItemWrapper>
            <FilterItemLabel>{t('font-styles.min-styles')}</FilterItemLabel>
            <Slider
              value={minStyles}
              min={1}
              max={5}
              afterValue="+"
              darkMode={darkMode}
              onAfterChange={handleChangeMinStyles}
            />
          </FilterItemWrapper>
          <FilterItemWrapper>
            <FilterItemLabel>{t('font-styles.contrast')}</FilterItemLabel>
            <Slider
              value={contrast}
              defaultIcon={iconContrastSlider}
              defaultLabel={labelContrastSlider}
              withTooltip
              min={-25}
              max={100}
              marks={marksContrastSlider}
              step={25}
              darkMode={darkMode}
              onChange={handleChangeContrast}
              onAfterChange={handleAfterChangeContrast}
            />
          </FilterItemWrapper>
          <FilterItemWrapper>
            <FilterItemLabel>{t('font-styles.width')}</FilterItemLabel>
            <Slider
              value={width}
              defaultIcon={iconWidthSlider}
              defaultLabel={labelWidthSlider}
              withTooltip
              min={-50}
              max={200}
              marks={marksWidthSlider}
              step={50}
              darkMode={darkMode}
              onChange={handleChangeWidth}
              onAfterChange={handleAfterChangeWidth}
            />
          </FilterItemWrapper>
          <FilterItemWrapper>
            <FilterItemLabel>{t('font-styles.ending')}</FilterItemLabel>
            <Slider
              value={ending}
              defaultIcon={iconEndingSlider}
              defaultLabel={labelEndingSlider}
              withTooltip
              min={1}
              max={3}
              marks={marksEndingSlider}
              step={1}
              darkMode={darkMode}
              onChange={handleChangeEnding}
              onAfterChange={handleAfterChangeEnding}
            />
          </FilterItemWrapper>
        </FilterItemsContainer>
      </ContentWrapper>
    </FontStylesWrapper>
  );
};

export default FontStyles;
